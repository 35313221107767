const webDevDe = [
  { name: "Ich entwickle die Benutzerschnittstelle" },
  { name: "Entwicklung von Websites" },
  { name: "Ich erstelle UX Element Interaktion" },
  { name: "Ich positioniere Ihr Unternehmen/Ihre persönliche Marke" },
  {
    name: "Entwicklung von qualitativ hochwertige und leistungsstarke Webanwendungen für Sie",
  },
];

export default webDevDe;
