const webReEn = [
  { name: "I redesing you entire UI for you" },
  { name: "Apply Semantic HTML and SEO best practices to your style" },
  { name: "I create appealing transitions and animations" },
  { name: "Make your site look profesional and up-to modern standards" },
  {
    name: "Development of an aestetic concept that please the eyes  ",
  },
];

export default webReEn;
