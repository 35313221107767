const educationDe = [
  {
    name: "The Front-End Developer Career Path Scrimba 2022",
    place: "Scrimba.com",
    time: "2021-2022",
    position: "l1",
  },
  {
    name: "Umschulung als Fachinformatiker AE.",
    place: "WBS-Deutschland",
    time: "2022-2024",
    position: "l2",
  },
  {
    name: "Google IT-Support Online Certification",
    place: "Google",
    time: "2020-2021",
    position: "l3",
  },
  {
    name: "Responsive Web Design Developer Certification at freecodecamp.org",
    place: "freecodecamp.org",
    time: "2020-2021",
    position: "l4",
  },
  {
    name: "Bachelor in Internationalen Beziehungen",
    place: "ISRI-Kuba",
    time: "2010-2015",
    position: "l5",
  },
];

export default educationDe;
