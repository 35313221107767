const skills_backend = [
  {
    skill: "Node Js",
    level: "Basic",
  },
  {
    skill: "Python",
    level: "Basic",
  },
  {
    skill: "SQL",
    level: "Basic",
  },
  {
    skill: "FireBase",
    level: "Basic",
  },
];

export default skills_backend;
