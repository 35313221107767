const presentationEn = {
  presentation_1: `Since always I've been passionate
  for helping others to see and to understand the world from a different perspective.To express, to communicate,
   to interconnect people and make our emotions and our knowledge universal.First through my political education, now through the interconnection possibilities that the world wide web offers us.`,

  presentation_2: `It's this will to help what motivates me to improve myself in order to be useful.I'm a partially self-taught devoloper that cannot get enough of 
   the new and extraordinary things this new digital world has to offer.
`,
};

export default presentationEn;
