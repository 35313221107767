const skills_frontend = [
  {
    skill: "HTML",
    level: "Intermediate",
  },
  {
    skill: "CSS",
    level: "Intermediate",
  },
  {
    skill: "JavaScript",
    level: "Intermediate",
  },
  {
    skill: "Bootstrap",
    level: "Intermediate",
  },
  {
    skill: "GIT",
    level: "Intermediate",
  },
  {
    skill: "React",
    level: "Intermediate",
  },
];

export default skills_frontend;
