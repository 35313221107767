const webDevEn = [
  { name: "I develop the user interface" },
  { name: "Web page development" },
  { name: "I create UX element intereaction" },
  { name: "I position your company/personal brand" },
  {
    name: "Development hight quality and performace strong web applications for you",
  },
];

export default webDevEn;
